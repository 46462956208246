import { format } from "date-fns";
import { graphql, Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import ReactUtterences from "react-utterances";

import CardPost from "../components/CardPost";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import LogoLabel from "../components/LogoLabel";
import MyProfile from "../components/MyProfile";
import { UTTERENCES_REPO, UTTERENCES_TYPE, WEBSITE_TITLE, WEBSITE_URL } from "../constants";
import { meta } from "../meta";
import * as styles from "./TemplatePost.css";

export const query = graphql`
  query TemplatePost($postPath: String!) {
    post: markdownRemark(fields: { postPath: { eq: $postPath } }) {
      frontmatter {
        title
        createdAt
        description
        thumbnail {
          childImageSharp {
            resize {
              src
              width
              height
            }
          }
        }
      }
      fields {
        postPath
      }
      html
    }
    relatedPosts: allMarkdownRemark(
      filter: { fields: { postPath: { ne: $postPath } } }
      sort: { fields: frontmatter___createdAt, order: DESC }
      limit: 4
    ) {
      nodes {
        id
        ...CardPost_post
      }
    }
  }
`;

interface TemplatePostProps {
  data: GatsbyTypes.TemplatePostQuery;
}
const TemplatePost: React.FC<TemplatePostProps> = (props) => {
  const { post, relatedPosts } = props.data;

  if (
    !post ||
    !post.frontmatter?.title ||
    !post.frontmatter?.createdAt ||
    !post.fields?.postPath ||
    !post.html
  ) {
    return null;
  }

  const title = `${post.frontmatter.title} - ${WEBSITE_TITLE}`;
  const { thumbnail } = post.frontmatter;

  return (
    <div className={styles.container}>
      <Helmet
        htmlAttributes={{
          lang: "ko",
        }}
        title={title}
        meta={[
          ...meta({
            url: WEBSITE_URL + post.fields?.postPath,
            title,
            description: post.frontmatter.description,
            thumbnail: {
              url: thumbnail?.childImageSharp?.resize?.src,
              width: thumbnail?.childImageSharp?.resize?.width,
              height: thumbnail?.childImageSharp?.resize?.height,
            },
          }),
        ]}
      />
      <div className={styles.top}>
        <div className={styles.topEmptyLogo} />
        <div className={styles.topLogoLabelContainer}>
          <Link to="/" className={styles.topLogoLabelLink}>
            <LogoLabel />
          </Link>
        </div>
      </div>
      <article className={styles.article}>
        <h1 className={styles.heading}>{post.frontmatter?.title}</h1>
        <div className={styles.caption}>
          {format(new Date(post.frontmatter.createdAt), "yyyy년 M월")}
        </div>
        <div
          className={styles.articleMain}
          dangerouslySetInnerHTML={{
            __html: post.html,
          }}
        />
        <div className={styles.profileContainer}>
          <MyProfile />
        </div>
        {relatedPosts.nodes.length > 0 ? (
          <div className={styles.relatedPosts}>
            <div className={styles.relatedPostsTitle}>추천</div>
            <div className={styles.relatedPostsGrid}>
              {relatedPosts.nodes.map((post) => (
                <CardPost key={post.id} post={post} />
              ))}
            </div>
            <Link to="/" className={styles.relatedPostsButton}>
              목록으로 돌아가기
            </Link>
          </div>
        ) : null}
        <div className={styles.comments}>
          <ReactUtterences repo={UTTERENCES_REPO} type={UTTERENCES_TYPE} />
        </div>
      </article>
      <Footer />
    </div>
  );
};

export default TemplatePost;
